<template>
  <div id="bd">
    <div id="ban-in">
      <div class="ban-bg"></div>
      <div class="banner-box wp">
        <h3>客户案例</h3>
        <h5>Customer Cases</h5>
      </div>
    </div>

    <!-- 客户案例 -->
    <div class="customerCase">
      <div class="wp">
        <div class="tit-i">
          <h3>精选客户案例</h3>
          <h5>
            SELECTED
            <span>CASES</span>
          </h5>
        </div>
        <div class="case-box">
          <ul class="case-box-tab">
            <li
              class="case-box-tab-item"
              @click="setActive(0)"
              :class="{ active: tab === 0 }"
            >
              全部
            </li>
            <div
              class="case-box-tab-item"
              @click="setActive(1)"
              :class="{ active: tab === 1 }"
            >
              工业管理
            </div>
            <div
              class="case-box-tab-item"
              @click="setActive(2)"
              :class="{ active: tab === 2 }"
            >
              园区运营
            </div>
            <div
              class="case-box-tab-item"
              @click="setActive(3)"
              :class="{ active: tab === 3 }"
            >
              产业指导
            </div>
            <div
              class="case-box-tab-item"
              @click="setActive(4)"
              :class="{ active: tab === 4 }"
            >
              企业服务
            </div>
          </ul>
          <ul class="ul-list">
            <li v-for="(item, index) in showExample" :key="index">
              <div class="pad">
                <div class="pic">
                  <a><img style="height: 220px" :src="item.img" /></a>
                </div>
                <div class="bor">
                  <div class="txt">
                    {{ item.title }}
                    <p :title="item.info">{{ item.info }}</p>
                  </div>
                </div>
              </div>
            </li>
            <div style="clear: both"></div>
          </ul>
        </div>
        <!--        <div class="customer-list">-->
        <!--          <div class="cc-left">-->
        <!--            <ul>-->
        <!--              <li @click="setActive(0)" :class="{ active: tab === 0 }">全部</li>-->
        <!--              <li @click="setActive(1)" :class="{ active: tab === 1 }">-->
        <!--                工业管理-->
        <!--              </li>-->
        <!--              <li @click="setActive(2)" :class="{ active: tab === 2 }">-->
        <!--                园区运营-->
        <!--              </li>-->
        <!--              <li @click="setActive(3)" :class="{ active: tab === 3 }">-->
        <!--                产业指导-->
        <!--              </li>-->
        <!--              <li @click="setActive(4)" :class="{ active: tab === 4 }">-->
        <!--                企业服务-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </div>-->
        <!--          <div class="cc-right">-->
        <!--            <ul>-->
        <!--              <li v-for="(item, index) in showExample" :key="index">-->
        <!--                <a href="javascript:;"-->
        <!--                  ><img :src="item.img" alt="" />-->
        <!--                  <div class="intro">-->
        <!--                    <h3 :title="item.title">-->
        <!--                      {{ item.title }}-->
        <!--                    </h3>-->
        <!--                    <p :title="item.info">-->
        <!--                      {{ item.info }}-->
        <!--                    </p>-->
        <!--                  </div>-->
        <!--                  <div class="casename" style="bottom: 0px">-->
        <!--                    {{ item.title }}-->
        <!--                  </div>-->
        <!--                </a>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tab: 0,
      show: false,
      showItem: {
        img: '',
        title: '',
        info: '',
      },
      // active: 0,
      showExample: [],
      examples: [
        {
          type: 1,
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/customercase/case3.png',
          title: '江西工业大数据应用平台',
          info: '江西工业大数据应用平台建有全省一体化横向互联、纵向互通的智慧园区综合平台和省智慧工业大数据中心，是全国数据体量最大、服务体系最全、接入园区最多的省级智慧工业平台。',
        },
        {
          type: 1,
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/customercase/case4.png',
          title: '宜春市智慧工业平台',
          info: '宜春市智慧工业平台通过数据汇聚，应用建模，形成有价值的分析和预测数据，以图表的形式进行直观展示，为分析、研判和预警工业经济情况提供决策参考。',
        },
        {
          type: 1,
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/customercase/case8.png',
          title: '新余市智慧工业平台',
          info: '新余市智慧工业平台是江西省内首个搭载市级“亩均效益”数据库的市级智慧云平台，它通过更直观的可视化展示…利用遥感监测、地理信息系统、互联网等技术手段，建立了综合监管机制，推动自然资源全程监管和高效配置。',
        },
        {
          type: 1,
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/customercase/case9.png',
          title: '萍乡市智慧工业平台',
          info: '萍乡市智慧工业平台通过数据汇聚，应用建模，形成有价值的分析和预测数据，以图表的形式进行直观展示，为分析、研判和预警工业经济情况提供决策参考。',
        },
        {
          type: 2,
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/customercase/case1.png',
          title: '井冈山经开区工业大数据',
          info: '井冈山经开区工业大数据平台能够全面、系统地采集开发区及企业的各种数据，进行可视化展示并提供决策支持。实现一站整合全维度数据，一图展示全时空信息，一键预测全行业态势。',
        },
        {
          type: 2,
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/customercase/case2.png',
          title: '九江经济技术开发区智慧云平台',
          info: '九江经济技术开发区智慧云平台全面、系统地采集开发区及企业的各种数据，进行可视化展示并提供决策支持。实现一站整合全维度数据，一图展示全时空信息，一键预测全行业态势。',
        },
        {
          type: 2,
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/customercase/case10.png',
          title: '万安县智慧园区云平台',
          info: '万安县智慧园区云平台建立了智慧安监应急系统，通过统一的数据中心可以制定统一的数据标准，方便数据的存储，以及数据共享、交换和挖掘工作。',
        },
        {
          type: 2,
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/customercase/case11.png',
          title: '铜鼓工业园智慧园区云平台',
          info: '铜鼓工业园智慧园区云平台全面、系统地采集开发区及企业的各种数据，进行可视化展示并提供决策支持。实现一站整合全维度数据，一图展示全时空信息，一键预测全行业态势。',
        },
        {
          type: 3,
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/customercase/case5.png',
          title: '江西省汽车产业智慧云平台',
          info: '江西省汽车产业智慧云平台由“汽车产业服务”和“汽车产业大数据”组成。为企业提供全方位一体化服务，为工业主管部门指导全省汽车产业发展提供数据依据和技术支撑。',
        },
        {
          type: 3,
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/customercase/case6.png',
          title: '江西锂电大数据中心',
          info: '江西锂电大数据中心是为宜春特色产业定制开发的产业大数据平台，利用大数据，从多个角度看待问题，并推测出真个形式的发展趋势和结果，辅助决策，从而实现更大的商业价值。',
        },
        {
          type: 4,
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/customercase/case7.png',
          title: '赣企服平台',
          info: '赣企服灵活用工网助力定南工业园区招工1500余人，促成华建英才人力资源公司与彩智电子科技有限公司长期合作招工协议。',
        },
        {
          type: 4,
          img: 'https://rh.2hrh.com/static/file/rhportal/image/front/customercase/case12.png',
          title: '赣企服APP',
          info: '赣企服APP是由江西融合科技公司自主研发的，专门为工业主管部门、园区管委会和企业提供集工业管理、企业服务、产业运营为一体的移动应用。',
        },
      ],
    };
  },
  watch: {
    // active(value) {
    //   this.setActive(value);
    // },
  },
  methods: {
    setShowItem(item) {
      this.showItem = { ...item };
      this.show = true;
    },
    setActive(index) {
      this.tab = index;
      if (index === 0) {
        this.showExample = [...this.examples];
      } else {
        this.showExample = this.examples.filter((i) => i.type === index);
      }
    },
  },
  created() {
    this.setActive(0);
  },
};
</script>
<style lang="scss" scoped>
#bd {
  //padding-top: 90px;
}
#ban-in {
  position: relative;
  background-position: center 0;
  background-repeat: no-repeat;
  /*padding-top: 16%;*/
  background-size: 100% 100%;
  height: 750px;
  background: url(../../assets/front/customercase/customerbg.png) center center
    no-repeat;
}
#ban-in .ban-bg {
  /* background: url(../../image/front/ban_bg.png) center top no-repeat; */
  height: 54px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: 60;
}
.banner-box {
  //height: 100%;
  padding-top: 250px;
  animation-name: fadeInUp;
  animation: fadeInUp 0.5s linear;
  h3 {
    font-size: 46px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
  h5 {
    margin-top: 18px;
    font-size: 20px;
    font-family: PingFang SC-Light, PingFang SC;
    font-weight: 300;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 8px;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
    -ms-transform: translateY(100px);
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
/* 客户案例 */
.customerCase .customer-list {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  min-height: 1000px;
  padding-bottom: 50px;
}
.customerCase .cc-left {
  width: 20%;
}
.customerCase .cc-left ul {
  border-right: 1px solid #ddd;
  margin-right: 30px;
  overflow: hidden;
}
.customerCase .cc-left ul li {
  font: 17px/40px 'PingFang SC';
  margin: 25px 0;
  cursor: pointer;
  padding-left: 50px;
  color: #666666;
  transition: all 0.3s;
}
.customerCase .cc-left ul li:hover {
  background: linear-gradient(
    90deg,
    rgba(31, 115, 255, 0) 0%,
    rgba(31, 115, 255, 0.1) 100%
  );
  border-right: 3px solid #1f73ff;
  color: #1f73ff;
}
.customerCase .cc-left ul li.active {
  background: linear-gradient(
    90deg,
    rgba(31, 115, 255, 0) 0%,
    rgba(31, 115, 255, 0.1) 100%
  );
  border-right: 3px solid #1f73ff;
  color: #1f73ff;
}
.customerCase .cc-right {
  width: 80%;
  height: 100%;
}
.customerCase .cc-right ul {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
.customerCase .cc-right li {
  width: 49%;
  height: 258px;
  margin-bottom: 2%;
  position: relative;
}

@-webkit-keyframes fadeInDown {
  0% {
    top: 100%;
  }

  100% {
    top: 0;
  }
}

@keyframes fadeInDown {
  0% {
    top: 100%;
  }

  100% {
    top: 0;
  }
}

.customerCase .cc-right li:hover .intro {
  animation: fadeInDown 0.5s linear;
  top: 0;
}

.customerCase .cc-right li:hover .casename {
  display: none;
}

.customerCase .cc-right li a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.customerCase .cc-right li:nth-of-type(odd) {
  margin-right: 2%;
}
.customerCase .cc-right li img {
  width: 100%;
  height: 100%;
}
.customerCase .cc-right li .intro {
  position: absolute;
  top: 100%;
  left: 0;
  max-width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 30px;
  padding-top: 70px;
  cursor: pointer;
}
.customerCase .cc-right li .intro h3 {
  font: 20px/30px 'PingFang SC';
  margin-bottom: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.customerCase .cc-right li .intro p {
  font: 14px/24px 'PingFang SC';
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.customerCase .cc-right li .casename {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  font: 20px/50px 'PingFang SC';
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 30px;
}
/* 产品服务 */
.moduleTitle {
  text-align: center;
  padding: 40px 0;
}
.moduleTitle h3,
.moduleTitleLeft h3 {
  font: 30px/40px 'PingFang SC';
  color: #333;
  margin-bottom: 10px;
}
.moduleTitle h5,
.moduleTitleLeft h5 {
  font-size: 20px;
  color: #c9c9c9;
  text-transform: uppercase;
  font-family: Arial;
  letter-spacing: 8px;
}
.moduleTitle h5 span,
.moduleTitleLeft h5 span {
  color: #1f73ff;
}
.moduleTitleLeft {
  padding: 40px 0;
}

.case-box {
  padding-bottom: 74px;
  .case-box-tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .case-box-tab-item {
    cursor: pointer;
    min-width: 55px;
    padding: 15px 44px;
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    text-align: center;
  }
  .case-box-tab-item.active {
    background: linear-gradient(
      180deg,
      rgba(31, 115, 255, 0) 0%,
      rgba(31, 115, 255, 0.1) 100%
    );
    border-bottom: 3px solid #1f73ff;
    color: #1f73ff;
  }
  .ul-list {
    margin-left: -15px;
    margin-right: -30px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .ul-list li {
    width: 30%;
    margin-bottom: 30px;
    cursor: pointer;
  }

  .ul-list li .pic img {
    width: 100%;
    display: block;
    transform: perspective(800px) rotateY(0deg);
    transition: all 1s ease 0s;
    -moz-transform: perspective(800px) rotateY(0deg);
    -moz-transition: all 1s ease 0s;
    -webkit-transform: perspective(800px) rotateY(0deg);
    -webkit-transition: all 1s ease 0s;
    -o-transform: perspective(800px) rotateY(0deg);
    -o-transition: all 1s ease 0s;
    -ms-transform: perspective(800px) rotateY(0deg);
    -ms-transition: all 1s ease 0s;
    zoom: 1;
  }
  .ul-list li:hover img {
    transform: perspective(800px) rotateY(0deg);
    -moz-transform: perspective(800px) rotateY(0deg);
    -webkit-transform: perspective(800px) rotateY(0deg);
    -o-transform: perspective(800px) rotateY(0deg);
    -ms-transform: perspective(800px) rotateY(0deg);
  }
  .ul-list li {
    padding: 0 8px;
  }
  .ul-list li .pad {
    border: 1px solid #e2e3e8;
  }
  .ul-list li:hover .pad {
    //border-color: #1071b5;
    border: none;
    box-shadow: 0px 0px 12px rgba(153, 153, 153, 0.6);
  }
  .ul-list li .txt {
    padding: 20px 14px;
    line-height: 20px;
    font-size: 16px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    p {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .ul-list li .more {
    height: 42px;
    line-height: 42px;
    border-top: 1px dotted #e2e3e8;
    background-color: #f0f2f5;
    padding: 0 14px;
    display: none;
  }
  .ul-list li .more a {
    color: #1071b5;
  }
  .ul-list li .more a:hover {
    text-decoration: underline;
  }
  .ul-list li .title {
    height: 55px;
    margin-bottom: 20px;
  }
  .ul-list li .title span {
    float: left;
    border: 1px solid #e2e3e8;
    background-color: #f0f2f5;
    display: inline-block;
    text-align: center;
    padding: 6px 5px;
    margin-right: 15px;
  }
  .ul-list li .title span em {
    display: block;
    font-size: 16px;
  }
  .ul-list li .title h3 {
    border-bottom: 1px solid #e2e3e8;
    overflow: hidden;
    height: 54px;
  }
  .ul-list li .txt {
    max-height: 175px;
  }
}
</style>
